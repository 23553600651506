






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetBiaozhuyuan } from "@/request/mark";
import { PutBookDetail } from "@/request/common";
@Component({})
export default class Name extends Vue {
  @Prop()
  private title: any; //分配、重新分配
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail = JSON.parse(JSON.stringify(this.data));
  }
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowFenpei = this.ifShow;
    if (this.ifShow) {
      this.getData();
    }
  }
  private ifShowFenpei: any = false;
  private detail: any = {}; // 修改的数据
  private biaozhuyuan: any = []; // 标注员选项
  private getData() {
    const params: any = {};
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
      this.$forceUpdate();
    });
  }
  private cancel() {
    this.$emit("cancel");
  }
  private fenpeisure() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      book_id: this.detail.book_id,
      check_user_id: this.detail.check_user_id,
    };
    PutBookDetail(this, params, loading).then((res: any) => {
      this.cancel();
      this.$emit("sure");
    });
  }
}
